import 'whatwg-fetch';
import * as jQuery from 'jquery';
import 'bootstrap/js/dist/modal';
import type Modal from 'bootstrap/js/dist/modal';
import './upsell.scss';

declare global {
	interface HTMLScriptElement {
		readyState: any;
		onreadystatechange: any;
	}

	interface JQuery {
		modal: Modal.jQueryInterface;
	}
}

declare namespace process {
	let env: {
		[key: string]: string
	}
}

export interface Order {
	id?: string;
}

export interface Customer {
	email?: string;
	first_name?: string;
	last_name?: string;
	company?: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	country?: string;
	zip?: string;
	phone?: string;
}

export interface OffersConfig {
	order_info: Order;
	customer_info: Customer;
	preview?: string;
}

interface UpsellSettings {
	enabled: boolean;
}

export class UpsellCore {
	private static readonly mainContainerUrl: string = process.env.UPSELL_APP_MAINCONTAINER_URL;
	private static readonly settingsUrl: string = process.env.UPSELL_APP_SETTINGS_URL;

	private shop: string | null = null;
	private widgetConfig: OffersConfig | null = null;
	private loaded: boolean = false;
	private offer_index: number = -1;
	private offer_tokens: string[] = [];

	protected loadSettings(): Promise<UpsellSettings> {
		return new Promise<UpsellSettings>((resolve, reject) => {
			// TODO we need to create a client to talk with the api instead of having code left and right.
			fetch(UpsellCore.settingsUrl + '?shop=' + this.shop, { credentials: 'omit' })
				.then(response => {
					if (!response.ok)
						throw new Error(response.statusText || 'An unknown error occured');

					return response.json();
				})
				.then(data => {
					resolve(data);
				})
				.catch(error => {
					resolve(null);
				});
		});
	};

	private addIFrameEventListener(obj, evt, func): void {
		if ('addEventListener' in obj) {
			obj.addEventListener(evt, func, false);
		}
		else if ('attachEvent' in obj) {
			obj.attachEvent('on', evt, func);
		}
	};

	private removeIFrameEventListener(obj, evt, func): void {
		if ('removeEventListener' in obj) {
			obj.removeEventListener(evt, func, false);
		}
		else if ('detachEvent' in obj) {
			obj.detachEvent('on', evt, func);
		}
	};

	private track(data: object) {
		fetch(process.env.UPSELL_APP_TRACK_URL + '', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
	}

	private trackImpression(): void {
		let token = (this.offer_index == -1) ? null : this.offer_tokens[this.offer_index];
		let data = {
			token: token,
			//TODO: include fingerprint
			name: 'impression',
			location: window.location.href
		};

		this.track(data);
	}

	private trackClose(): void {
		let token = (this.offer_index == -1) ? null : this.offer_tokens[this.offer_index];
		let data = {
			token: token,
			//TODO: include fingerprint
			name: 'close',
			location: window.location.href
		};

		this.track(data);
	}

	public init(shop: string): void {
		this.shop = shop;
	};

	public hideOffers() {
		return new Promise<void>((resolve, reject) => {
			jQuery("#upsell_widget_modal").modal('hide');
			resolve();
		});
	};


	public showOffers(config: OffersConfig): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (this.loaded) {
				reject(new Error('Upsell offers are already shown.'));
			}

			this.loaded = true;
			this.widgetConfig = config;

			let messageListener = (event) => {
				if (event && event.data && event.data.msg) {
					if (event.data.msg == 'AgoraLabs.Upsell.ShowWidget') {
						this.offer_tokens = [];
						this.offer_index = -1;
						var offers = event.data.data;

						for (var i = 0; i < offers.length; i++) {
							this.offer_tokens.push(offers[i].track);
						}

						if (offers.length > 0) {
							jQuery("#upsell_widget_modal").modal('show');
							this.offer_index = 0;
							this.trackImpression();
						}
					}
					else if (event.data.msg == 'AgoraLabs.Upsell.HideWidget') {
						this.trackClose();
						jQuery("#upsell_widget_modal").modal('hide');
					}
					else if (event.data.msg == 'AgoraLabs.Upsell.DataRequest') {
						let response: any = {};
						switch (event.data.type) {
							case 'LoadInfo':
								if (this.widgetConfig) {
									response.customer_info = this.widgetConfig.customer_info;
									response.preview = this.widgetConfig.preview;
								}
								break;
						}
						let container = document.getElementById('upsell_widget_container') as HTMLIFrameElement;
						if (container && container.contentWindow) {
							(container.contentWindow.postMessage({ msg: 'AgoraLabs.Upsell.DataResponse', type: event.data.type, data: response }, '*'))
						}
					}
					else if (event.data.msg == 'AgoraLabs.Upsell.Error') {
						this.removeIFrameEventListener(window, 'message', messageListener);
						jQuery('#upsell_widget_container').attr('src', 'about:blank');
						jQuery('#upsell_widget_container').remove();
						this.loaded = false;
						reject(new Error(event.data.data));
					}
					else if (event.data.msg == 'AgoraLabs.Upsell.SelectedOfferChange') {
						var index = event.data.data;
						this.offer_index = index;
						this.trackImpression();

					}
				}
			};


			this.addIFrameEventListener(window, 'message', messageListener);

			let orderId = (this.widgetConfig?.order_info?.id || '');
			let skipOffers = 'Skip all offers';

			var html_content = jQuery(
				'<div class="upsell-bootstrap-container upsell-bootstrap">' +
				'	<div id="upsell_widget_modal" data-backdrop="static" class="modal fade" tabindex="-1">' +
				'		<button type="button" class="close text-light" data-dismiss="modal" style="">&times;</button>' +
				'		 <div class="modal-dialog modal-dialog-centered">' +
				'			<div id="upsell_widget_modal_content" class="modal-content drop-shadow">' +
				'				<iframe id="upsell_widget_container" ></iframe>' +
				'			</div>' +
				'		</div>' +
				'	</div>' +
				'</div>'
			);

			jQuery(document.body).append(html_content);
			jQuery('#upsell_widget_modal').on('hidden.bs.modal', () => {
				this.removeIFrameEventListener(window, 'message', messageListener);
				jQuery('#upsell_widget_container').attr('src', 'about:blank');
				jQuery('#upsell_widget_container').remove();
				this.loaded = false;
				resolve();
			});
			let src = UpsellCore.mainContainerUrl + '?shop=' + this.shop + '&order_id=' + orderId + '&ts=' + new Date().getTime();
			jQuery('#upsell_widget_container').attr('src', src);
		});
	};

};